import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Wrapper mdxType="Wrapper">
      <h1>{`Über uns`}</h1>
      <hr></hr>
      <h2 {...{
        "id": "die-leitsatze-der-firma-sind"
      }}>{`Die Leitsätze der Firma sind:`}</h2>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Der Umwelt- und Gesundheitsschutz unserer Mitarbeiter, hat für uns oberste Priorität.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Präzise und zuverlässige Lösungen für individuelle Ansprüche der Kunden zu finden.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Bestreben nach langfristigen und partnerschaftlichen Kundenbeziehungen.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Kundenbegeisterung dauerhaft sicherstellen.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Kundenwünsche schnell, flexibel und zuverlässig erfüllen.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Einen hohen Qualitätsanspruch an sich selbst stellen.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Direkte und offene Kommunikation.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Jeder Mitarbeiter verhält sich kollegial und unterstützt somit eine gute Zusammenarbeit.`}</p>
          <br />
          <h2 parentName="li" {...{
            "id": "qualitatspolitik-des-unternehmens"
          }}>{`Qualitätspolitik des Unternehmens`}</h2>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Präzise und zuverlässige Lösungen für individuelle Ansprüche der Kunden zu finden.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Bestreben nach langfristigen und partnerschaftlichen Kundenbeziehungen.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Kundenbegeisterung dauerhaft sicherstellen.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Kundenwünsche schnell, flexibel und zuverlässig erfüllen.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Einen hohen Qualitätsanspruch an sich selbst stellen.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Direkte und offene Kommunikation untereinander.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Jeder Mitarbeiter verhält sich kollegial und unterstützt somit eine gute Zusammenarbeit.`}</p>
        </li>
      </ul>
  <br />
      <h2 {...{
        "id": "ziele-des-unternehmens-fur-20242025"
      }}>{`Ziele des Unternehmens für 2024/2025`}</h2>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Modernisierung einzelner Fertigungsmaschinen.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Ausbildungsmaßnahmen von Mitarbeitern.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Umsatzsteigerung durch Akquise von Neukunden.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Energieeinsparung durch evtl. Solartechnik.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Fuhrpark dauerhaft umstellen auf Wasserstoff- oder Elektroenergie.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Entwicklung/ Erweiterung von neuen Produkten.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Modernisierung der Trockenkammer (Neue Software und Hardware), damit die Trocknung von Paletten effizienter wird.`}</p>
          <br />
          <h2 parentName="li" {...{
            "id": "umweltrichtlinien-fur-das-unternehmen-bilstein-paletten-gmbh--cokg"
          }}>{`Umweltrichtlinien für das Unternehmen Bilstein Paletten GmbH & Co.KG`}</h2>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Im Jahr 2002 wurde eine Biomasseholzkraftanlage angeschafft. Diese beheizt mit Abfallholz den gesamten Gewerbepark, die Trockenkammern und Wohnungseinheiten.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Es wird ausschließlich folgendes Material verwendet: Schrottpaletten, die nicht mehr repariert werden können.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Bretter aus der Produktion, die nicht verwendbar sind, aufgrund von Materialfehlern, Schimmel etc. Diese werden gesondert in Containern gesammelt, welche zur Schredderstation befördert werden. Der Schredder verarbeitet dieses Material zu Hackschnitzeln und befördert diese mit Hilfe eines Fließbandes direkt in einen großen Bunker hinein. Dieser ist mit der Heizungsanlage verbunden, der diese Hackschnitzel auf direktem Weg vollautomatisch in die Heizung transportiert.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Des Weiteren verarbeiten wir lange Brettware, die durch ein Hobelwerk laufen und danach auf einer Mehrfachablängsäge auf das gewünschte Maß gekappt werden.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Die durch das Hobelwerk angefallenen Holzspäne werden mit Hilfe einer Absauganlage in einem gesonderten Einblascontainer gelagert.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Sobald dieser gefüllt ist, wird die Spanplattenindustrie informiert, damit die Sägespäne zu Spanplatten verarbeitet und somit wiederverwertet werden können.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Die Reststücke der Brettware werden in einem separaten Container gelagert und der Schredderhalle zugeführt, die wiederum zu Hackschnitzeln verarbeitet werden zwecks Zuführung in die Heizungsanlage.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Defekte Paletten werden von uns repariert und dem Kunden zurückgeführt.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Für die Neuherstellung von Paletten verwenden wir zu 98% PEFC zertifiziertes Holz.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Für den Transport wurden LKWs entsprechend der EURO 6 Norm angeschafft.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Des Weiteren werden im Betrieb Elektrostapler und Dieselstapler mit Partikelfilter eingesetzt.`}</p>
          <hr parentName="li"></hr>
        </li>
      </ul>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      